import { EchoEventType, getEchoSrv } from '@grafana/runtime';

import { config } from '../config';
import { backendSrv } from '../services/backend_srv';
import { GrafanaJavascriptAgentEchoEvent } from '../services/echo/backends/grafana-javascript-agent/types';

function sendUnknownKeyMetric(key: string) {
  if (!config.bootData.user.isSignedIn) {
    return;
  }
  backendSrv.post('/api/frontend-metrics', {
    events: [
      {
        name: 'frontend_unknown_local_storage_key',
        value: 1,
      },
    ],
  });
}

function sendUnknownKeyNameLog(key: string) {
  getEchoSrv().addEvent<GrafanaJavascriptAgentEchoEvent>({
    type: EchoEventType.GrafanaJavascriptAgent,
    payload: {
      type: 'log',
      payload: {
        message: `Found unknown local storage key: ${key}`,
      },
    },
  });
}

const seenKeys = new Set<string>();
function handleUnknownKey(key: string) {
  if (!seenKeys.has(key)) {
    seenKeys.add(key);
    sendUnknownKeyNameLog(key);
    sendUnknownKeyMetric(key);
  }
}

function __reset() {
  seenKeys.clear();
}

export { __reset, handleUnknownKey };
